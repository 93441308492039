<template>
  <div id="app" class="container mt-4">
    <h1 class="text-center mb-4">Welcome to Rollihes!</h1>

    <!-- 검색창 -->
    <div class="mb-4">
      <input
        type="text"
        class="form-control"
        placeholder="검색어를 입력하세요..."
        v-model="searchQuery"
        @input="fetchData(1)"
      />
    </div>

    <!-- 데이터 테이블 -->
    <div class="table-responsive">
      <table class="table table-striped table-bordered text-center">
        <thead class="table-dark">
          <tr>
            <th>이름</th>
            <th>연락처</th>
            <th>주소</th>
            <th>상품명</th>
            <th>등록 날짜</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="item in items" :key="item.num">
            <td>{{ item.name }}</td>
            <td>{{ item.phone }}</td>
            <td>{{ item.address }}</td>
            <td>{{ item.subject }}</td>
            <td>{{ item.regdate }}</td>
          </tr>
          <tr v-if="items.length === 0">
            <td colspan="5" class="text-center">데이터가 없습니다.</td>
          </tr>
        </tbody>
      </table>
    </div>

    <!-- 페이지네이션 -->
    <nav aria-label="Page navigation">
      <ul class="pagination justify-content-center">
        <li class="page-item" :class="{ disabled: currentPage === 1 }">
          <button class="page-link" @click="fetchData(currentPage - 1)">이전</button>
        </li>
        <li
          class="page-item"
          v-for="page in totalPages"
          :key="page"
          :class="{ active: currentPage === page }"
        >
          <button class="page-link" @click="fetchData(page)">{{ page }}</button>
        </li>
        <li class="page-item" :class="{ disabled: currentPage === totalPages }">
          <button class="page-link" @click="fetchData(currentPage + 1)">다음</button>
        </li>
      </ul>
    </nav>
  </div>
</template>

<script>
export default {
  data() {
    return {
      items: [],
      currentPage: 1,
      totalPages: 1,
      limit: 10,
      searchQuery: "",
    };
  },
  methods: {
    fetchData(page) {
      const query = this.searchQuery ? `&search=${this.searchQuery}` : "";
      fetch(`/data?page=${page}&limit=${this.limit}${query}`)
        .then((response) => response.json())
        .then((data) => {
          this.items = data.data;
          this.currentPage = data.page;
          this.totalPages = Math.ceil(data.total / this.limit);
        })
        .catch((error) => {
          console.error("API 호출 중 오류:", error);
        });
    },
    formatDate(dateString) {
      const options = { year: 'numeric', month: '2-digit', day: '2-digit' };
      return new Date(dateString).toLocaleDateString('ko-KR', options);
    },
  },
  mounted() {
    this.fetchData(this.currentPage);
  },
};
</script>

<style>
.table {
  font-size: 0.9rem;
}
.table th {
  background-color: #343a40;
  color: white;
}
.table-hover tbody tr:hover {
  background-color: #f1f1f1;
}
</style>
